import React from 'react'

function Hero() {
    return (
        <div className="home-hero">
            <h1 className="hero-header">
                We Do<br></br>Everything<br></br>Music
            </h1>
        </div>
    )
}

export default Hero
